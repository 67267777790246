import { render, staticRenderFns } from "./FooterGER.html?vue&type=template&id=0fa64f9e&scoped=true&external"
import script from "./FooterGER.js?vue&type=script&lang=js&external"
export * from "./FooterGER.js?vue&type=script&lang=js&external"
import style0 from "./FooterGER.scss?vue&type=style&index=0&id=0fa64f9e&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fa64f9e",
  null
  
)

export default component.exports